<template>
  <div id="Project--detail-container">
    <div>
      <router-link to="/edit-mode-projects/">
        <span>GO BACK TO PROJECTS LIST</span>
      </router-link>
    </div>
    <div v-if="currentProject" class="edit-form">
      <h4  class="Project--detail-container--title">Project {{currentProject.title}}</h4>
      <form>
        <div class="form-group">
          <label class="Project--detail-container--label" for="title">Title</label>
          <input type="text" class="form-control" id="title"
            v-model="currentProject.title"
          />
        </div>
        <div class="form-group">
          <label class="Project--detail-container--label" for="description">Description</label>
          <input type="text" class="form-control" id="description"
            v-model="currentProject.description"
          />
        </div>
        <!-- Editing project date input date picker -->
        <div class="form-group">
          <label class="Project--detail-container--label">
            <strong>Date:</strong>
          </label>
          <input id="date-picker"
                v-model="currentProject.date" type="date"/>
        </div>

        <!-- Editing project location -->
        <div class="form-group">
          <label class="Project--detail-container--label">
            <strong>Location:</strong>
          </label>
          <input v-model="currentProject.location"/>
        </div>

        <!-- Editing project background color project-->
        <div class="form-group">
          <label class="Project--detail-container--label">
            <strong>Background color project:</strong>
          </label>
          <input id="ProjectShowEditMode--colorPicker"
                v-model="currentProject.backgroundColor"
                type="color">
        </div>
        <!-- Project status info -->
        <div class="form-group Project--detail-container--label">
          <label>
            <strong>Status:</strong>
          </label>
          {{ currentProject.published ? "Published" : "Pending" }}
        </div>
      </form>

     
      <button class="badge badge-primary mr-2"
      v-if="currentProject.published"
      @click="updatePublished(false)"
      >
        UnPublish
      </button>

      <button v-else class="badge badge-primary mr-2"
        @click="updatePublished(true)"
      >
        Publish
      </button>

      <button class="badge badge-danger mr-2"
        @click="deleteProject"
      >
        Delete
      </button>

      <button type="submit" class="badge badge-success"
        @click="updateProject"
      >
        Update
      </button>
      <!-- Message from database -->
      <p class="Project--detail-container--message">{{ message }}</p>

    </div>
    <div v-if="currentProject" class="Project--CreateGridLayout">
      <CreateGridLayout
          :projectID="currentProject.id"
          :projectTitle="currentProject.title"
          @current-section-data="(n) => getGridLayoutSectionData(n)"
          />
    </div>
</div>
</template>

<script>
import ProjectDataService from "../../../services/project/ProjectDataService";
import CreateGridLayout from "../grid_layout/CreateGridLayout.vue";
// import Tooltip from "../../../components/edit_mode/tooltip/Tooltip.vue";

export default {
  name: "project",
  data() {
    return {
      currentProject: null,
      message: '',
    };
  },
  components: {
    CreateGridLayout,
    // Tooltip
  },
  methods: {
    // We retreiving all the data via the ProjectDataService from the database for the selected projet
    getProject(id) {
      ProjectDataService.get(id)
        .then(response => {
          // We're assigning the value of the currentProject variable with all the data of the current project
          this.currentProject = response.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    updatePublished(status) {
      // HERE we sent all the project to the database via ProjectDataService
      var data = {
        id: this.currentProject.id,
        title: this.currentProject.title,
        description: this.currentProject.description,
        date: this.currentProject.date,
        location: this.currentProject.location,
        backgroundColor: this.currentProject.backgroundColor,
        published: status
      };

      ProjectDataService.update(this.currentProject.id, data)
        .then(response => {
          // Where the response.data is coming from (Backend)?
          /*
           ==> \server_postgreql\app\controllers\project.controller.js
           */
          console.log("updatePublished response.data (Project.vue) =", response.data);
          this.currentProject.published = status;
          this.message = 'The status was updated successfully!';
        }
        )
        .catch(e => {
          console.log(e);
        });
    },

    updateProject() {
      ProjectDataService.update(this.currentProject.id, this.currentProject)
        .then(response => {
          console.log("updateProject response.data          (Project.vue) =", response.data);
          // console.log("update this.currentProject    (Project.vue) =", this.currentProject);
          // console.log("update this.currentProject.id (Project.vue) =", this.currentProject.id);

          this.message = 'The project was updated successfully!';

          // ------------------- Update The VueX State - Delete Project -----------------*
          // dispatching the action 'update' from the vueX store
          // IMPORTANT THE FIRST  PARAM TYPE ==> INTEGER ==> response.data.id
          // IMPORTANT THE SECOND PARAM TYPE ==> DATA OBJECT

          const data = {
              id: response.data.id,
              userID: response.data.userID,
              title: response.data.title,
              description: response.data.description,
              date: response.data.date,
              location: response.data.location,
              backgroundColor: response.data.backgroundColor,
              published: response.data.published,
              sectionData: response.data.sectionData,
              createdAt: response.data.createdAt,
              updatedAt: response.data.updatedAt
          }

          this.$store.dispatch("project/update", data).then(
              () => {
                console.log(" Update Project VueX success")
              },
           )
        })
        .catch(e => {
          console.log(e);
        });
    },

    deleteProject() {
      ProjectDataService.delete(this.currentProject.id)
        .then(response => {
          console.log(response.data);
          this.$router.push({ name: "projects" });
        })
        .catch(e => {
          console.log(e);
        });
    },

       // Handle emitted data from (child) ==> CreateGridLayout.vue
    /*
        - How the (child) ==> CreateGridLayout.vue emits data towards the (parent) ==> Project.vue?

          + Here the syntax of the click event used inside the (child) ==> CreateGridLayout.vue :
              ...
              Save(){
                  // Emmitted data to the parent ==> (Project.vue) to Edit the targeted json databases files
                  this.$emit('currentSectionData', this.sections)
              }

// Send a notification of sucess
},


        - How the (parent) ==> Project.vue receives data from the (child) ==> CreateGridLayout.vue ?

        + Here the syntax of the click event used inside the (parent) ==> Project.vue  :
              ...
              <CreateGridLayout
                  :projectID="currentProject.id"
                  :projectTitle="currentProject.title"
                  @current-section-data="(n) => getGridLayoutSectionData(n)"/>

    */
    getGridLayoutSectionData: function(gridLayoutData) {
            console.log("gridLayoutData emitted from (CreateGridLayout.vue) to (Project.vue) =", gridLayoutData)
            this.gridLayoutName      = gridLayoutData;
    },

  },
  mounted() {
    this.message = '';
    this.getProject(this.$route.params.id);

    // The event listener beforeunload detect changes inside the current page
    // The alert dialog box will display only if there did have changes on the DOM
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = "confirmation beforeunload";
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage;                            //Webkit, Safari, Chrome
    });
    // console.log("currentProject      =", this.currentProject)

  },
  updated: function(){
    // Change dynamically the background color of div container of the current project detail page
    document.getElementById("Project--detail-container").style.backgroundColor = this.currentProject.backgroundColor;
  }
};
</script>

<style scoped>
  .edit-form {
    text-align: center;
  }

  /* IMPORTANT THE CONTAINER MUST HAVE A BACKGROUND COLOR TO MAKES THE TEX VISIBLE  */
  #Project--detail-container {
    background-color: white ;
  }

  #Project--detail-container div.edit-form h4 {
  padding-top: 2%;
  padding-bottom: 2%;
  margin: 0;
}

.Project--detail-container--label,
.Project--detail-container--title,
.Project--detail-container--message {
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-family: Helvetica, arial, sans-serif;
    font-size: 1vw;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;
    mix-blend-mode: difference;
}
</style>
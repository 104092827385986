<template>
<div class="UploadFilesForm--container">
    <!-- In the code above, we use Bootstrap Progress Bar:

        .progress as a wrapper
        inner .progress-bar to indicate the progress
        .progress-bar requires style to set the width by percentage
        .progress-bar also requires role and some aria attributes to make it accessible
        label of the progress bar is the text within it.

        https://www.bezkoder.com/vue-multiple-files-upload/
    -->

    <!-- UploadFilesForm progess bar -->
    <div v-if="progressInfos"
         class="UploadFilesForm--progress-container">
      <div class=""
        v-for="(progressInfo, index) in progressInfos"
        :key="index"
      >
        <span class="UploadFilesForm--progressInfo-filename">{{progressInfo.fileName}}</span>

        <div class="UploadFilesForm--progress">
          <div class="UploadFilesForm--progress-bar UploadFilesForm--progress-bar-info"
            role="progressbar"
            :aria-valuenow="progressInfo.percentage"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="{ width: progressInfo.percentage + '%' }"
          >
            {{progressInfo.percentage}}%
          </div>
        </div>
      </div>
    </div>

    <!-- Upload form -->
    <div class="UploadFilesForm--label-container">
      <label class="UploadFilesForm--label">
        <form enctype="multipart/form-data" class="UploadFilesForm--form">
          <input type="file" multiple @change="selectFile" />
        </form>
      </label>
    </div>


    <!-- Upload button -->
    <div class="UploadFilesForm--upload-button-container">
      <button class="UploadFilesForm--upload-button"
        :disabled="!selectedFiles"
        @click="uploadFiles"
      >
        Upload
      </button>
    </div>


    <!-- Alert message -->
    <div v-if="message" class="UploadFilesForm--alert UploadFilesForm--alert-light" role="alert">
      <div>
        <p v-for="(ms, i) in message.split('\n')" :key="i">
          {{ ms }}
        </p>
      </div>
    </div>

    <!-- We get this data from input radio type

        <input class="UploadFilesForm--list-group-radio-button"
                      type="radio"
                      :id="index"
                      :value="{fileUrl:file.url, fileName: file.name}"
                      v-model="picked" />
     -->

    <h3 class="">Current main project infos:</h3>

    <div>SECTION INDEX - {{ sectionID }}</div>
    <div>project INDEX - {{ projectID}}</div>
    <div>projectTitle  - {{ projectTitle }}</div>
    <div>gridLayoutItemIndex  - {{ gridLayoutItemIndex }}</div>

    <!-- <h4>GRID LAYOUT ITEM INDEX - {{ item.i }}</h4> -->
    <h3 class="">The selected picture infos:</h3>

    <div>The picture object data selected is (object) : {{ picked }}</div>
    <div>The picture name selected is : {{ picked.fileName }}</div>
    <div>The picture url selected is : {{ picked.fileUrl }}</div>

    <!-- Upload file form card -->
    <div class="UploadFilesForm--card">
      <div class="UploadFilesForm--card-header">
        <h3>List of files associated to the project - {{ projectTitle }} </h3>
      </div>
      <div class="UploadFilesForm--list-group">
        <!-- Uplaod file form card item -->
        <div class="UploadFilesForm--list-group-item"
              v-for="(file, index) in fileInfos"
              :key="index"
        >
          <Tooltip
               :tooltipText="'Select this picture to import it'"
               :flow="'up'">
              <div>
                <!--
                    Form Input Bindings: https://vuejs.org/guide/essentials/forms.html#radio
                    IMPORTANT: we make all these data available through the variable "picked"
                    ...
                    :value="{fileUrl:file.url, fileName: file.name}"

                 -->
                  <input class="UploadFilesForm--list-group-radio-button"
                    type="radio"
                    :id="index"
                    :value="{fileUrl:file.url, fileName: file.name, fileSize: file.size}"
                    v-model="picked" />
              </div>
          </Tooltip>

          <!-- <Tooltip :tooltipText="'Click to zoom'"> -->
            <div class="UploadFilesForm--list-item-picture-Container"
              @click="getSelectedFileInfos($event, file.url, file.name, file.size)">
              aspect Ratio {{ file.aspectRatio }}
              <!--
                The aspect ratio formula is used to determine the proportional relationship between
                the width and height of an image or screen.
                It is typically expressed as a ratio of the width to the height.

                The formula for aspect ratio is:

                Aspect Ratio = Width / Height

                For example, if an image has a width of 1920 pixels and a height of 1080 pixels, the aspect ratio can be calculated as follows:

                Aspect Ratio = 1920 / 1080 = 16:9

                This means that the width of the image is 16 times greater than its height.

                The aspect ratio can also be expressed as a decimal,

                in this case 1.78 (which is obtained by dividing 16 by 9).
               -->
               <!-- 
                    ## Calcul new width with aspect ratio formula

                    To calculate the new width of an image or screen with a given aspect ratio and height, you can use the following formula:

                    New Width = Aspect Ratio x Height

                    For example, if you have an image with an aspect ratio of 4:3 and a height of 600 pixels, you can calculate the new width as follows:

                    New Width = 4/3 x 600 = 800 pixels

                    This means that the new width of the image will be 800 pixels, while maintaining the original aspect ratio of 4:3.
                -->

              <img class="UploadFilesForm--list-item-picture" :src='file.url' :width="file.aspectRatio * 180" height="180"/>
            </div>
          <!-- </Tooltip> -->

          <!-- File infos columns -->
          <div>
            <p>
                 Name: <a :href="file.url">{{ file.name }}</a>
            </p>
            <p>
                 Size: {{ file.size }}
            </p>
            <p>
                 Dimension: ( W-{{ file.imageOrientationDimension.width}} px - H - {{ file.imageOrientationDimension.height }} px)
            </p>
            <p>
                 aspect ratio: {{ file.aspectRatio  }}
            </p>
            <p>
                 orientation: {{ file.imageOrientationDimension.orientation}}
            </p>
          </div>

          <!-- File path columns -->

          <div>
               Localization: <p :href="file.url">{{ file.url }}</p>
          </div>

        </div>
       </div>

       <div>
          <!-- Upload Modal Slot Teleport and Body -->
          <Teleport to="body">
              <zoom-image-modal :show="showModal"
                                @close="showModal = false">
                  <!-- Use the modal component, pass in the prop with slot component-->
                  <template #ZoomImageModal--header>
                      <!-- Here the header slot value from the modal component -->
                      <h2>Zoom View</h2>
                  </template>

                  <!-- Use the modal component, pass in the prop with slot component-->
                  <template #ZoomImageModal--body>
                      <!-- Here the body slot value from the modal component -->
                      <!-- {{ currentFileUrl }} -->
                      <img class="ZoomImageModal--picture" :src='currentFileUrl'/>
                  </template>
              </zoom-image-modal>
          </Teleport>
        </div>
    </div>
  </div>
</template>

<script>
import UploadService from "../../../services/file/UploadFilesService";
import Tooltip from "../../../components/edit_mode/tooltip/Tooltip.vue";
import ZoomImageModal from '../../edit_mode/modal/ZoomImageModal.vue';

/*
  We're calling the 'UploadFilesForm.vue' component inside the 'UploadModal.vue' modal component
  called inside the 'Example01Basic.vue' component dynamic grid layout.
  UploadFilesForm.vue > UploadModal.vue > Example01Basic.vue
*/
export default {
  name: "upload-files-form",
  props: {
      sectionID: {type: String, required: true},
      projectID: {type: Number, required: true},
      projectTitle: {type: String, required: true},
      gridLayoutItemIndex: {type: Object, required: true},
  },
  components: {
    Tooltip,
    ZoomImageModal
  },
  // Then we define the some variables inside data()
  data() {
    return {
      selectedFiles: undefined,
      currentFileUrl: null,
      currentFileName: null,
      progressInfos: [],
      message: "",
      showModal: false,
      fileInfos: [],
      picked: ""
    };
  },
  methods: {
    selectFile() {
        /*
            Next we define selectFiles() method which helps us
            to get the selected Files from <input type="file" > element later.
        */
        this.progressInfos = [];
        this.selectedFiles = event.target.files;
        console.log("this.selectedFiles =", this.selectedFiles)
    },
    uploadFiles() {
        /*
            selectedFiles array will be used for accessing current selected Files.
            Every file has a corresponding progress Info (percentage, file name) and index in progressInfos array.
        */
        this.message = "";

        for (let i = 0; i < this.selectedFiles.length; i++) {
            this.upload(i, this.selectedFiles[i]);
        }
    },
    upload(idx, file) {
        /*
            We call UploadFilesService.upload() method on each file with a callback.
            So create following upload() method:
        */
        console.log("upload method() file (UploadFilesForm.vue) =", file)

        this.progressInfos[idx] = { percentage: 0, fileName: file.name };

        UploadService.upload(file, (event) => {
            this.progressInfos[idx].percentage = Math.round(100 * event.loaded / event.total);
        })
        .then((response) => {
            let prevMessage = this.message ? this.message + "\n" : "";
            this.message = prevMessage + response.data.message;

            return UploadService.getFiles();
        })
        .then((files) => {
            /*
              Where the value files.data came from (BACKEND)?
                ==> \server_postgreql\app\controllers\file.controller.js
              ...
              let fileInfos = [];

              files.forEach((file) => {
                fileInfos.push({
                  name: file,
                  url: baseUrl + file, // WARNING IF THE BASE URL IS WRONG WE CAN'T SEND THE CORRECT PATH TO THE FRONTEND
                });
              });
            */
            this.fileInfos = files.data;
            console.log("this.fileInfos (UploadFilesForm.vue) =", this.fileInfos)
        })
        .catch(() => {
            this.progressInfos[idx].percentage = 0;
            this.message = "Could not upload the file:" + file.name;
        });
    },

     getSelectedFileInfos (event, fileUrl, fileName) {
        this.showModal = true
        this.currentFileUrl  = fileUrl
        this.currentFileName = fileName

        console.log("this.currentFileUrl  =", this.currentFileUrl)
        console.log("this.currentFileName =", this.currentFileName)
    }
  },
  mounted() {
        /*
            The progress will be calculated basing on event.loaded and event.total.
            If the transmission is done, we call UploadFilesService.getFiles() to get the files' information
            and assign the result to fileInfos state, which is an array of {name, url} objects.
            We also need to do this work in mounted() method:
        */
        UploadService.getFiles().then((response) => {
            /*
              Where the value files.data came from (BACKEND)?
                ==> \server_postgreql\app\controllers\file.controller.js
              ...
              let fileInfos = [];

              files.forEach((file) => {
                fileInfos.push({
                  name: file,
                  url: baseUrl + file, // WARNING IF THE BASE URL IS WRONG WE CAN'T SEND THE CORRECT PATH TO THE FRONTEND
                });
              });
            */
            this.fileInfos = response.data;
        });
    }
};
</script>

<style>
.UploadFilesForm--list-group-item {
  border-top:1px solid gray;
  display: grid;
  grid-template-columns: .5fr 1fr 1fr 1fr;
  padding-top: 1em;
  padding-bottom: 1em;
  gap: 10px;
}

.UploadFilesForm--list-group-item:hover {
  background-color: aliceblue;
}

.UploadFilesForm--card-header,
.UploadFilesForm--alert,
.UploadFilesForm--label-container,
.UploadFilesForm--upload-button-container,
.UploadFilesForm--progress-container {
  text-align: center;
  padding-bottom: .5em;
}

.UploadFilesForm--list-item-picture:hover {
  transform: scale(1.2);
  transition: transform .8s;
}

.UploadFilesForm--list-group-item {
  cursor: pointer;
}
.UploadFilesForm--list-item-picture-Container {
  text-align: center;
  height: 100%;
  width: 100%;
}

.UploadFilesForm--list-group-radio-button {
  cursor: pointer;
  margin-left: 50%;
}
</style>
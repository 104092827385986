<template>
    <div>
        <button @click="addItem">Add an item dynamically</button>

        <grid-layout v-model:layout="layout"
                    :col-num="12"
                    :row-height="30"
                    :is-draggable="draggable"
                    :is-resizable="resizable"
                    :vertical-compact="true"
                    :use-css-transforms="true"
        >
            <grid-item v-for="(item, j) in layout"
                    :key="j"
                    :static="item.static"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.index"
            >
                <div class="GridLayout-RemoveItem--Container">
                        <span class="GridLayout-Item--remove" @click="removeItem(item.i)">
                            <Tooltip :tooltipText="'Delete'"
                             :flow="'up'">x
                            </Tooltip>
                        </span>
                </div>

                <!-- To display the unique id of the gridLayout item -->
                <div>
                    <span class="GridLayout--item-info">{{itemTitle(item)}}</span>
                </div>

                <!-- ADD NEW CONTENT MODAL -->
                <div class="GridLayout--item-header-menu">
                    <!-- Add a new project -->
                    <Tooltip :tooltipText="'Custom content'">
                        <button @click="OpenModal($event, item)">
                            <RiAddFill />
                        </button>
                    </Tooltip>
                </div>

                <div>
                    <!-- Upload Modal Slot Teleport and Body -->
                    <Teleport to="body">
                        <upload-modal :show="showModal" @close="showModal = false">
                            <!-- Use the modal component, pass in the prop with slot component-->
                            <template #UploadImageModal--header>
                                <!-- Here the header slot value from the modal component -->
                                <h2>Manage Media Content - {{ projectTitle }} (PROJECT ID - {{ projectID }})</h2>
                            </template>

                            <!-- Use the modal component, pass in the prop with slot component-->
                            <template #UploadImageModal--body>
                                <!-- Here the body slot value from the modal component -->
                                <upload-files-form
                                    :sectionID="sectionID"
                                    :projectTitle="projectTitle"
                                    :projectID="projectID"
                                    :gridLayoutItemIndex="selectedGridLayoutItemData">
                                </upload-files-form>
                            </template>
                        </upload-modal>
                    </Teleport>
                </div>
            </grid-item>
        </grid-layout>
    </div>
</template>

<script>
import { GridLayout, GridItem } from "vue3-grid-layout"
import Tooltip from "../../../components/edit_mode/tooltip/Tooltip.vue";
import UploadFilesForm from "../../../components/edit_mode/upload/UploadFilesForm.vue";
import UploadModal from '../../edit_mode/modal/UploadImageModal.vue';
import RiAddFill from '../../../components/icons/features/add/RiAddFill.vue'

export default {
    components: {
        GridLayout,
        GridItem,
        Tooltip,
        UploadFilesForm,
        UploadModal,
        RiAddFill
    },
    props:{
        sectionID:{type: String, required: true},
        projectID:{type: Number, required: true},
        projectTitle: {type: String, required: true}
    },
    data() {
        return {
            /* Layout can contain anything:
              - videos (upload features)
              - pictures  (upload features)
              - gif   (upload features)
              - audio (upload features)
              - text   (input form features)
              - etc
            */
            showModal: false,
            /*
                The layout value will be set to default if the value
                of the layout from VueX Store and the databse is null.
            */
            layout: [
                {
                    "x":0,"y":0,"w":2,"h":2,
                    // "i": "s-"+ this.sectionID + "_0",
                    i: 0,
                    static: false,
                    type: "IMAGE",
                    projectName:"bachavibes",
                    imagePath: "",
                    videoPath: "",
                    gifPath: "",
                    audioPath: "",
                    text: "",
                },
                {
                    "x":2,"y":0,"w":2,"h":4,
                    // "i": "s-"+ this.sectionID + "_1",
                    i: 1,
                    static: true,
                    type: "IMAGE",
                    projectName:"bachavibes",
                    imagePath: "",
                    videoPath: "",
                    gifPath: "",
                    audioPath: "",
                    text: "",
                },
            ],
            draggable: true,  // false ==> to pass to the deploy mode
            resizable: true,  // false ==> to pass to the deploy mode
            colNum: 12,
            index: 2, // IMPORTANT START WITH TH NUMBER OF ITEM BY DEFAULT
            selectedGridLayoutItemData: null
        }
    },
    methods: {
        // ---
        itemTitle(item) {
            let result = item.i;
            if (item.static) {
                result += " - Static";
            }
            return result;
        },
        // Insert the correct path of the picture (FOR TESTING)
        getImgUrlImageProjects: function (projectName, imagePath) {
            return require('@/assets/img/projects/' + projectName + '/' + imagePath);
        },

        // --- Add and remove Grid Layout Item ---
        addItem: function () {
            // Add a new item. It must have a unique key!
            this.layout.push({
                x: (this.layout.length * 2) % (this.colNum || 12),
                y: this.layout.length + (this.colNum || 12), // puts it at the bottom
                w: 2,
                h: 2,
                // WE NEED TO AVOID DUPLICATED INDEX SO EVERY INDEX MUST BE INDEPENDANT TO THE VALUE ==> this.layout.length
                i: this.index,
                // DON'T FORGET THIS PART
                projectName:"",
                imagePath: "",
                videoPath: "",
                gifPath: "",
                audioPath: "",
                text: "",
            });
            // Increment the counter to ensure key is always unique.
            this.index++;

            console.log("this.layout updated (addItem) =", this.layout)
        },
        removeItem: function (val) {
            const index = this.layout.map(item => item.i).indexOf(val);
            this.layout.splice(index, 1);
        },
        OpenModal: function (event, currentSelectedGridLayoutItemData) {
            console.log("selectedGridLayoutItemData - OpenModal -- (Example01Basic.vue) =", currentSelectedGridLayoutItemData)
            this.showModal = true
            this.selectedGridLayoutItemData = currentSelectedGridLayoutItemData
        },
    },
    mounted(){
        console.log("this.sectionID                           =", this.sectionID)
        console.log("this.layout mounted (Example01Basic.vue) =", this.layout)
    }
}
</script>

<style scoped>
/* .vue-grid-layout {
    background: #eee;
} */

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add,
.GridLayout-RemoveItem--Container,
.GridLayout--item-header-menu {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

/* Remove feature css */
.GridLayout-RemoveItem--Container {
   margin-left: 1em;
}

.GridLayout-Item--remove {
    padding-top: 0.3em;
    margin-left: 8.5em;
    position: fixed;
    z-index: 99;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-family: Helvetica, arial, sans-serif;
    font-size: 1.5vw;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;
    mix-blend-mode: difference;
}

.GridLayout--item-header-menu {
    text-align: center;
}



</style>

<style scoped>
 @import "../../../assets/css/edit-mode/createGridLayout.scss";
</style>
<template>
    <div id="CreateGridLayout__container">
        <div class="CreateGridLayout__menu_anchor_link--section">
          <button
                  @click="addSectionDiv()"
                  style="">Add new section</button>
          <!-- Anchor-link-to-jump-to-a-specific-part-of-a-page -->
          <div v-for="(section, section_idx1) in sections"
            :key="section_idx1">
            <Tooltip  :tooltipText="`Go to section ${section_idx1}`"
                      :flow="'left'">
              <a
                :href="`#section-${section_idx1}`">
                section-{{section_idx1}}
              </a>
            </Tooltip>
          </div>
        </div>
        <div  v-if="count<1"
              class=""
              style="margin-top: 20px;">
          <button
            @click="addSectionDiv()"
            style="">Add new section</button>
          <!-- Upload file component -->
          <!-- <UploadFiles/> -->
        </div>
        <div v-for="(section, section_idx) in sections"
            :key="section_idx"
            :id="`section-${section_idx}`"
            :style="{'color':section?.textColor,
                      'backgroundColor':section?.backgroundColor,
                      'height':section?.defaultHeight,
                      'width':section?.defaultWidth
                    }"
          >

          <!-- Add button and save button anywhere but the first section -->
          <div
                class="CreateGridLayout__section--header--add-save">
                <button
                  @click="addSectionDiv()"
                  style="">Add new section</button>
                <!-- Save features ==> Update api call  -->
                <button
                  @click="Save()"
                  style="">Save sections modification(s)</button>
                <button
                  :id="section_idx"
                  @click="removeSectionDiv($event)">Remove this section
                </button>
                <!-- <div>
                  <router-link to="/edit-mode-projects/">
                    <h3>GO BACK TO PROJECTS LIST</h3>
                  </router-link>
                </div> -->
          </div>
          <div class="CreateGridLayout--section-container">
            <div class="CreateGridLayout--section-header">
                <span>
                  {{ section?.title }}
                      -
                  {{ section_idx }}
                </span>
            </div>
            <!-- Dropdown multiSelect GridLayouts -->
            <SingleSelect
                :sectionID="section_idx.toString()"
                @select-grid-layout="(n) => getGridLayoutName(n)"/>
          </div>
          <!--
          <button
            :id="i"
            @click="addGridLayoutContainerToSelectedSection($event)"
            style="position: relative; top: 50px;">add grid container
          </button>
          -->

          <!------------- Adding Grid Layout Component ------------->
          <!-------------------- Default Grid Layout --------------->
          <!-- <Example01Basic
                v-if="section?.defaultGridLayout"
                :sectionID="section?.id.toString()" /> -->

          <!-- Check if the sectionID of the item of the multiselect === the id of the section v-for iteration-->
          <Example01Basic
                v-if="section?.currentgridLayout == 'Example01Basic'"
                :sectionID="section?.id.toString()"
                :projectID="projectID"
                :projectTitle="projectTitle"/>
          <Example02Events
                v-if="section?.currentgridLayout == 'Example02Events'"
                :sectionID="section?.id.toString()"/>
          <Example03MultipleGrids
                v-if="section?.currentgridLayout == 'Example03MultipleGrids'"
                :sectionID="section?.id.toString()"/>
          <Example04AllowIgnore
                v-if="section?.currentgridLayout == 'Example04AllowIgnore'"
                :sectionID="section?.id.toString()"/>
          <Example05Mirrored
                v-if="section?.currentgridLayout == 'Example05Mirrored'"
                :sectionID="section?.id.toString()"/>
          <Example06Responsive
                v-if="section?.currentgridLayout == 'Example06Responsive'"
                :sectionID="section?.id.toString()"/>
          <Example07PreventCollision
                v-if="section?.currentgridLayout == 'Example07PreventCollision'"
                :sectionID="section?.id.toString()"/>
          <Example08ResponsivePredefinedLayouts
                v-if="section?.currentgridLayout == 'Example08ResponsivePredefinedLayouts'"
                :sectionID="section?.id.toString()"/>
          <Example09DynamicAddRemove
                v-if="section?.currentgridLayout== 'Example09DynamicAddRemove'"
                :sectionID="section?.id.toString()"/>
          <!-- <Example10DragFromOutside
                v-if="gridLayoutName == 'Example10DragFromOutside'"
                :sectionID="section?.id.toString()"/> -->
          <Example11Bounded
                v-if="section?.currentgridLayout == 'Example11Bounded'"
                :sectionID="section?.id.toString()"/>
          <ExampleStylingGridLines
                v-if="section?.currentgridLayout == 'ExampleStylingGridLines'"
                :sectionID="section?.id.toString()"/>
          <ExampleStylingPlaceholder
                v-if="section?.currentgridLayout == 'ExampleStylingPlaceholder'"
                :sectionID="section?.id.toString()"/>
        </div>
    </div>
</template>
<script scoped>
// --- GridLayout components ---
import Example01Basic from "./Example01Basic.vue";
import Example02Events from "./Example02Events.vue";
import Example03MultipleGrids from "./Example03MultipleGrids.vue";
import Example04AllowIgnore from "./Example04AllowIgnore.vue";
import Example05Mirrored from "./Example05Mirrored.vue";
import Example06Responsive from "./Example06Responsive.vue";
import Example07PreventCollision from "./Example07PreventCollision.vue";
import Example08ResponsivePredefinedLayouts from "./Example08ResponsivePredefinedLayouts.vue";
import Example09DynamicAddRemove from "./Example09DynamicAddRemove.vue";
// import Example10DragFromOutside from "./Example10DragFromOutside.vue"; // NOT WORKING
import Example11Bounded from "./Example11Bounded.vue"; // SMOOTH !!
import ExampleStylingGridLines from "./ExampleStylingGridLines.vue"; // GRID AXES
import ExampleStylingPlaceholder from "./ExampleStylingPlaceholder.vue";
import SingleSelect from "../multiselect/SingleSelect.vue";

// --- UploadFiles components ---
// import UploadFiles from "../../components/edit_mode/upload/UploadFiles.vue"
import Tooltip from "../../../components/edit_mode/tooltip/Tooltip.vue";

// The component CreateGridLayout.vue is called inside the Project.vue component

export default {
  name: "CreateGridLayout",
  props: {
            projectID:{
                type: Number, required: true
            },
            projectTitle: {
                type: String, required: true
            }
  },
  // DOCUMENTATION: https://vuejs.org/guide/components/events.html#declaring-emitted-events
  emits: ['currentSectionData'],
  data() {
    return {
      sections: [],
      gridLayoutName: "",
      currentSectionIndex: "",
      selectedGridlayout: "",
      count: 1,
      message: 'Are you sure to quit the page?',

      // Project Name Editing
      projectName: this.$route.params.slug,
    }
  },
  components: {
    Example01Basic,
    Example02Events,
    Example03MultipleGrids,
    Example04AllowIgnore,
    Example05Mirrored,
    Example06Responsive,
    Example07PreventCollision,
    Example08ResponsivePredefinedLayouts,
    Example09DynamicAddRemove,
    // Example10DragFromOutside,
    Example11Bounded,
    ExampleStylingGridLines,
    ExampleStylingPlaceholder,
    SingleSelect,
    // Upload component
    // UploadFiles,
    Tooltip
  },
  computed: {
  },
  beforeUpdate() {
    // console.log("beforeUpdate")
  },
  methods: {
    getImgUrlImageProjects: function (projectName, imagePath) {
        return require('@/assets/img/projects/' + projectName + '/' + imagePath);
    },
    // Add section event
    addSectionDiv() {
      console.log("ADD this.sections =", this.sections)
      // section div has no defaultHeight so it will take the height of the first child ==> The gridlayout
      // const currentgridLayoutObject = JSON.parse(JSON.stringify(this.gridLayoutName))
      // console.log("currentgridLayout  =", currentgridLayoutObject.text)

      this.sections.push({id: this.count,
                          title:'Section',
                          textColor: 'black',
                          defaultWidth: '100%',
                          defaultHeight: '',
                          defaultGridLayout: true,
                          backgroundColor: "transparent",
                          currentgridLayout: "Example01Basic", // the default state of the gridlayout
                        });

      // We 're updating the count up               
      this.countUP()
    },

    // Image adding event will be triggered at the level of a child inside of the current section
    addImageToSelectedChildCurrentSection(event) {
      const sectionIndex = event.target.id
      console.log("sectionIndex =", sectionIndex)
    },
    // Text adding event will be triggered at the level of a child inside of the current section
    addTextToSelectedChildCurrentSection(event) {
      const sectionIndex = event.target.id
      console.log("sectionIndex =", sectionIndex)
    },
    // Removing section event will be triggered at the level of the current section
    removeSectionDiv(event){
      const sectionIndex = event.target.id
      // console.log("sectionIndex =", sectionIndex)
      // this.sections is a Proxy type value
      // .splice() ==> 2nd parameter means remove one item only
      this.sections.splice(sectionIndex, 1)

      if(this.sections.length === 0){
        this.countReset()
      }
    },
    // Each time we generate a new section we assign random color background
    // random_bg_color() {
    //     const x = Math.floor(Math.random() * 256);
    //     const y = Math.floor(Math.random() * 256);
    //     const z = Math.floor(Math.random() * 256);
    //     const bgColor = "rgb(" + x + "," + y + "," + z + ")";
    //   return bgColor
    // },
    // We will reset the counter of the sections when all section have been removed from the DOM
    countReset() {
      if(this.sections.length === 0){
         this.count = 0
      }
    },
    countUP() {
      // Increment the count value
      this.count++;
    },
    countDown() {
      // Decrement the count value
      this.count--;
    },
    Save(){

        /* DOCUMENTATION:
              https://vuejs.org/guide/components/events.html#emitting-and-listening-to-events
              https://learnvue.co/articles/vue-emit-guide
              https://stackoverflow.com/questions/67587463/use-emit-without-a-click-event
        */

        // Emmitted data to the parent ==> (Project.vue) to Edit the targeted json databases files
        this.$emit('currentSectionData', this.sections)

        // Update current project data via ProjectDataService.js

        // Send a notification of success
    },
    alertTest: function (event) {
      // `this` fait référence à l'instance de Vue à l'intérieur de `methods`
      alert('Bonjour ' + this.message + '!')
      // `event` est l'évènement natif du DOM
      if (event) {
        alert(event.target.tagName)
      }
    },
    // HANDLING EMITTED DATA: from (child) ==> ExampleDynamicGridLayout.vue to (parent) ==> CreateGridLayout.vue

    // WE NEED TO RECEIVE THE DATA OF THE DYNAMIC LAYOUT GRID LAYOUT TO SAVE THOSE TO THE DATABASE



    // HANDLING EMITTED DATA: from (child) ==> SingleSelect.vue to (parent) ==> CreateGridLayout.vue

    /*
        - How the (child) ==> SingleSelect.vue emits data towards the (parent) ==> CreateGridLayout.vue ?

          + Here the syntax of the click event used inside the (child) ==> SingleSelect.vue :
              ...
              @click="$emit('selectGridLayout', item)"


        - How the (parent) ==> CreateGridLayout.vue receives data from the (child) ==> SingleSelect.vue ?

        + Here the syntax of the click event used inside the (parent) ==> CreateGridLayout.vue :
              ...
              <SingleSelect
                  :sectionID="section_idx.toString()"
                  @select-grid-layout="(n) => getGridLayoutName(n)"/>
    */
    getGridLayoutName: function(gridLayoutData) {
            console.log("gridLayoutData =", gridLayoutData)
            this.gridLayoutName      = gridLayoutData;

            // Get the section id from the gridLayout data from the multiselect (children)
            const currentSectionID      = gridLayoutData.section_ID;
            console.log("currentSectionID =", currentSectionID)

            // Now we can update the section state
            // Now the currentSectionID exist in the state of (this.sections)
            console.log("target section state =", this.sections[currentSectionID])

            // Now we can update the gridLayout value for the state of current section
            this.sections[currentSectionID].currentgridLayout = gridLayoutData.text
    },
  },
  mounted: function () {
    console.log("currentProject      =", this.currentProject)
  },
  updated: function(){
    // We need to emit the current state of the this.sections to the parent
    console.log("sections state  (CreateGridLayout.vue)    =", this.sections)
  }
};
</script>

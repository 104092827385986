<template>
    <div>
        <!-- sectionID props will make all events of its components unique -->
        <div :class="`singleSelect--dropdown s--dnd--${sectionID}`">
            <button  @click="myFunction()"
                     :class="`singleSelect--dropbtn s--dpbtn--${sectionID}`">
                     {{dropdownButtonValue}}
            </button>
            <ul :id="`s--mydnd--${sectionID}`"
                :class="`singleSelect--dropdown-content s--dnd-c--${sectionID}`">

                <!-- Grid Layouts for landing section -->
                <li class="singleSelect--dropdown-label">
                    <label>Grid Layouts - Landing (Images/Gifs)</label>
                </li>
                <li v-for="(item, i) in options"
                        :key="i"
                        @click="$emit('selectGridLayout', item)"
                        :class="`singleSelect--dropdown-item s--dnd-i--${sectionID}`">
                        <!-- We're emitting to the parent the name of grid layout ==> (item.text)
                            and the id of the section ==> (i)
                        -->
                        {{item.text}}
                </li>

                <!-- Grid Layouts for body section-->
                <li class="singleSelect--dropdown-label">
                    <label>Grid Layouts - Body (Images/Gifs)</label>
                </li>
                <li v-for="(item, i) in options"
                        :key="i"
                        @click="$emit('selectGridLayout', item)"
                        :class="`singleSelect--dropdown-item s--dnd-i--${sectionID}`">
                        <!-- We're emitting to the parent the name of grid layout ==> (item.text)
                            and the id of the section ==> (i)
                        -->
                        {{item.text}}
                </li>

                <!-- Grid Layouts for  Next/Prev section -->
                <li class="singleSelect--dropdown-label">
                    <label>Grid Layouts - Next/Prev (Images/Gifs)</label>
                </li>
                <li v-for="(item, i) in options"
                        :key="i"
                        @click="$emit('selectGridLayout', item)"
                        :class="`singleSelect--dropdown-item s--dnd-i--${sectionID}`">
                        <!-- We're emitting to the parent the name of grid layout ==> (item.text)
                            and the id of the section ==> (i)
                        -->
                        {{item.text}}
                </li>

                <!-- Grid Layouts for Animated Layouts for all section -->
                <li class="singleSelect--dropdown-label">
                    <label>Animated Layouts (Images/Gifs)</label>
                </li>
                <li v-for="(item, i) in options"
                        :key="i"
                        @click="$emit('selectGridLayout', item)"
                        :class="`singleSelect--dropdown-item s--dnd-i--${sectionID}`">
                         <!-- We're emitting to the parent the name of grid layout ==> (item.text)
                            and the id of the section ==> (i)
                        -->
                        {{item.text}}
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default ({
        name: "Projects",
        components: {
        },
        props: {
                sectionID:{type: String, required: true}
        },
        data() {
            return {
                dropdownButtonValue: "Choose your grid layout for the section "+ this.sectionID,
                placeholder: "Select a grid layout",
                options: [
                            {
                                id: 0,
                                section_ID:this.sectionID,
                                text:"Example01Basic"
                            },
                            {
                                id: 1,
                                section_ID:this.sectionID,
                                text:"Example02Events"
                            },
                            {
                                id: 2,
                                section_ID:this.sectionID,
                                text:"Example03MultipleGrids"
                            },
                            {
                                id: 3,
                                section_ID:this.sectionID,
                                text:"Example04AllowIgnore"
                            },
                            {
                                id: 4,
                                section_ID:this.sectionID,
                                text:"Example05Mirrored"
                            },
                            {
                                id: 5,
                                section_ID:this.sectionID,
                                text:"Example06Responsive"
                            },
                            {
                                id: 6,
                                section_ID:this.sectionID,
                                text:"Example07PreventCollision"
                            },
                            {
                                id: 7,
                                section_ID:this.sectionID,
                                text:"Example08ResponsivePredefinedLayouts"
                            },
                            {
                                id: 8,
                                section_ID:this.sectionID,
                                text:"Example09DynamicAddRemove"
                            },
                            // {
                            //     id: 9,
                            //     section_ID:this.sectionID,
                            //     text:"Example10DragFromOutside"
                            // },
                            {
                                id: 10,
                                section_ID:this.sectionID,
                                text:"Example11Bounded"
                            },
                            {
                                id: 11,
                                section_ID:this.sectionID,
                                text:"ExampleStylingGridLines"
                            },
                            {
                                id: 12,
                                section_ID:this.sectionID,
                                text:"ExampleStylingPlaceholder"
                            },
                        ],
            }
        },
        computed:{
        },
        mounted: function(){
            /* When the user clicks on the button,
                toggle between hiding and showing the dropdown content */

                // Close the dropdown if the user clicks outside of it
                window.onclick = function(event) {
                if (!event.target.matches('.singleSelect--dropbtn')) {
                    var dropdowns = document.getElementsByClassName("singleSelect--dropdown-content");
                    var i;
                    for (i = 0; i < dropdowns.length; i++) {
                        var openDropdown = dropdowns[i];
                        if (openDropdown.classList.contains('singleSelect--show')) {
                            openDropdown.classList.remove('singleSelect--show');
                        }
                    }
                }
                }
        },
        updated: function(){
        },
        methods: {
            myFunction() {
                // target id ==> singleSelect--myDropdown 
                document.getElementById("s--mydnd--" + this.sectionID).classList.toggle("singleSelect--show");
            },
        },
    })
</script>
<style scoped>
    .singleSelect--dropdown-content  {
        list-style-type: none;
        font-family: "Times New Roman", Times, serif;
        padding: 0;
        margin:0;
    }
    .singleSelect--dropbtn {
        background-color: #3498DB;
        color: white;
        padding: 16px;
        font-size: 16px;
        border: none;
        cursor: pointer;
    }
    .singleSelect--dropbtn:hover,
    .singleSelect--dropbtn:focus {
        background-color: #2980B9;
    }
    .singleSelect--dropdown {
        position: relative;
        display: inline-block;
    }
    .singleSelect--dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 160px;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }
    .singleSelect--dropdown .singleSelect--myDropdown:hover {
        background-color: #ddd;
    }
    .singleSelect--show {
        display: block;
        overflow-y: visible;
        height: 450px;
    }
    .singleSelect--dropdown-item {
        padding: 5% 50px 5% 10%; /* top | right | bottom | left */
        border: 0.5px solid gray;
    }

    .singleSelect--dropdown-item:hover {
        background-color: #3498DB;
    }
    .singleSelect--dropdown-label {
        background-color: #7d8d97;
    }
</style>
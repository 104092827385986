<template>
    <div>
        <div ref="eventsDiv" class="eventsJSON">
            <div v-for="(event, j) in eventLog"
                :key="j"
            >
                {{event}}
            </div>
        </div>
        <!-- Add item feature -->
        <button @click="addItem">Add an item dynamically</button>

        <div style="margin-top:10px;">
            <grid-layout v-model:layout="layout"
                         :col-num="12"
                         :row-height="30"
                         :is-draggable="draggable"
                         :is-resizable="resizable"
                         :vertical-compact="true"
                         :use-css-transforms="true"
                         @layout-created="layoutCreatedEvent"
                         @layout-before-mount="layoutBeforeMountEvent"
                         @layout-mounted="layoutMountedEvent"
                         @layout-ready="layoutReadyEvent"
                         @layout-updated="layoutUpdatedEvent"
            >
                <grid-item v-for="(item, i) in layout"
                           :key="i"
                           :x="item.x"
                           :y="item.y"
                           :w="item.w"
                           :h="item.h"
                           :i="item.i"
                           @resize="resizeEvent"
                           @move="moveEvent"
                           @resized="resizedEvent"
                           @container-resized="containerResizedEvent"
                           @moved="movedEvent"
                >
                    <div class="GridLayout-RemoveItem--Container">
                            <span class="GridLayout-Item--remove" @click="removeItem(item.i)">
                                <Tooltip :tooltipText="'Delete'"
                                :flow="'up'">x
                                </Tooltip>
                        </span>
                    </div>
                    <!-- To display the unique id of the gridLayout item -->
                    <!-- <span class="text">{{item.i}}</span> -->

                    <!--
                        We can load picture inside a gridLayout item with aspect ratio
                        style="width:100%;height: auto;"
                    -->
                    <img
                        :data-src="getImgUrlImageProjects(item.projectName, item.imagePath)"
                        :src="getImgUrlImageProjects(item.projectName, item.imagePath)"
                        style="width:auto;height: 100%;">
                </grid-item>
            </grid-layout>
        </div>
    </div>
</template>

<script>
import { GridLayout, GridItem } from "vue3-grid-layout"
import Tooltip from "../../../components/edit_mode/tooltip/Tooltip.vue";

export default {
    components: {
        GridLayout,
        GridItem,
        Tooltip
    },
    props:{
            sectionID:{type: String, required: true},
    },
    data() {
        // You must to give a unique id to the key i
        return {
            /* Layout can contain anything:
              - videos (upload features)
              - pictures  (upload features)
              - gif   (upload features)
              - audio (upload features)
              - text   (input form features)
              - etc
            */
            layout: [
                {
                    "x":0,"y":0,"w":2,"h":2,
                    "i":"s-"+ this.sectionID + "_0",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
                },
                {   "x":2,"y":0,"w":2,"h":4,
                    "i":"s-"+ this.sectionID + "_1",
                    static: true,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00002.jpg"
                },
                {   "x":4,"y":0,"w":2,"h":5,
                    "i":"s-"+ this.sectionID + "_2",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00003.jpg"
                },
                {
                    "x":6,"y":0,"w":2,"h":3,
                    "i":"s-"+ this.sectionID + "_3",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00009.jpg"
                },
                {
                    "x":8,"y":0,"w":2,"h":3,
                    "i":"s-"+ this.sectionID + "_4",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "warsawsalsafestival-elsol-lnacarla-497.jpg"
                },
                {
                    "x":10,"y":0,"w":2,"h":3,
                    "i":"s-"+ this.sectionID + "_5",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "warsawsalsafestival-elsol-lnacarla-533.jpg"
                },
                {
                    "x":0,"y":5,"w":2,"h":5,
                    "i":"s-"+ this.sectionID + "_6",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "warsawsalsafestival-elsol-lnacarla-674.jpg"
                },
                {
                    "x":2,"y":5,"w":2,"h":5,
                    "i":"s-"+ this.sectionID + "_7",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "warsawsalsafestival-elsol-lnacarla-680.jpg"
                },
                {
                    "x":4,"y":5,"w":2,"h":5,
                    "i":"s-"+ this.sectionID + "_8",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "warsawsalsafestival-elsol-lnacarla-690.jpg"
                },
                {
                    "x":6,"y":3,"w":2,"h":4,
                    "i":"s-"+ this.sectionID + "_9",
                    static: true,
                    projectName:"bachavibes",
                    imagePath: "warsawsalsafestival-elsol-lnacarla-691.jpg"
                },
                {
                    "x":8,"y":4,"w":2,"h":4,
                    "i":"s-"+ this.sectionID + "_10",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
                },
                {
                    "x":10,"y":4,"w":2,"h":4,
                    "i":"s-"+ this.sectionID + "_11",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
                },
                {
                    "x":0,"y":10,"w":2,"h":5,
                    "i":"s-"+ this.sectionID + "_12",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
                },
                {
                    "x":2,"y":10,"w":2,"h":5,
                    "i":"s-"+ this.sectionID + "_13",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
                },
                {
                    "x":4,"y":8,"w":2,"h":4,
                    "i":"s-"+ this.sectionID + "_14",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
                },
                {
                    "x":6,"y":8,"w":2,"h":4,
                    "i":"s-"+ this.sectionID + "_15",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
                },
                {
                    "x":8,"y":10,"w":2,"h":5,
                    "i":"s-"+ this.sectionID + "_16",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
                },
                {
                    "x":10,"y":4,"w":2,"h":2,
                    "i":"s-"+ this.sectionID + "_17",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
                },
                {
                    "x":0,"y":9,"w":2,"h":3,
                    "i":"s-"+ this.sectionID + "_18",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
                },
                {
                    "x":2,"y":6,"w":2,"h":2,
                    "i":"s-"+ this.sectionID + "_19",
                    static: false,
                    projectName:"bachavibes",
                    imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
                }
            ],
            draggable: true,  // false ==> to pass to the deploy mode
            resizable: true,  // false ==> to pass to the deploy mode
            colNum: 12,
            index: 0,
            eventLog: []
        }
    },
    watch: {
        eventLog: function() {
            const eventsDiv = this.$refs.eventsDiv;
            eventsDiv.scrollTop = eventsDiv.scrollHeight;
        }
    },
    methods: {
        // --- For events ---
        moveEvent: function(i, newX, newY){
            const msg = "MOVE i=" + i + ", X=" + newX + ", Y=" + newY;
            this.eventLog.push(msg);
            console.log(msg);
        },
        movedEvent: function(i, newX, newY){
            const msg = "MOVED i=" + i + ", X=" + newX + ", Y=" + newY;
            this.eventLog.push(msg);
            console.log(msg);
        },
        resizeEvent: function(i, newH, newW, newHPx, newWPx){
            const msg = "RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);
        },
        resizedEvent: function(i, newX, newY, newHPx, newWPx){
            const msg = "RESIZED i=" + i + ", X=" + newX + ", Y=" + newY + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);
        },
        containerResizedEvent: function(i, newH, newW, newHPx, newWPx){
            const msg = "CONTAINER RESIZED i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);
        },
        layoutCreatedEvent: function(newLayout){
            this.eventLog.push("Created layout");
            console.log("Created layout: ", newLayout)
        },
        layoutBeforeMountEvent: function(newLayout){
            this.eventLog.push("beforeMount layout");
            console.log("beforeMount layout: ", newLayout)
        },
        layoutMountedEvent: function(newLayout){
            this.eventLog.push("Mounted layout");
            console.log("Mounted layout: ", newLayout)
        },
        layoutReadyEvent: function(newLayout){
            this.eventLog.push("Ready layout");
            console.log("Ready layout: ", newLayout)
        },
        layoutUpdatedEvent: function(newLayout){
            this.eventLog.push("Updated layout");
            console.log("Updated layout: ", newLayout)
        },
        // Insert the correct path of the picture (FOR TESTING)
        getImgUrlImageProjects: function (projectName, imagePath) {
            return require('@/assets/img/projects/' + projectName + '/' + imagePath);
        },
        // --- Add and remove Grid Layout Item ---
        addItem: function () {
            // Add a new item. It must have a unique key!
            this.layout.push({
                x: (this.layout.length * 2) % (this.colNum || 12),
                y: this.layout.length + (this.colNum || 12), // puts it at the bottom
                w: 2,
                h: 2,
                i: this.index,
                static: false,
                projectName:"bachavibes",
                imagePath: "elsolsalsafestival-lnacarla-friday-f00001.jpg"
            });
            // Increment the counter to ensure key is always unique.
            this.index++;
        },
        removeItem: function (val) {
            const index = this.layout.map(item => item.i).indexOf(val);
            this.layout.splice(index, 1);
        },
    },
    mounted(){
        console.log("this.sectionID =", this.sectionID)
        console.log("this.layout    =", this.layout)
    }
}
</script>

<style scoped>
/* .vue-grid-layout {
    background: #eee;
} */

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}

.eventsJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
    height: 100px;
    overflow-y: scroll;
}

/* Remove feature css */
.GridLayout-RemoveItem--Container {
   margin-left: 1em;
}

.GridLayout-RemoveItem--Container:hover {
    cursor: pointer;
}

.GridLayout-Item--remove {
    padding-top: 0.3em;
    margin-left: 8.5em;
    position: fixed;
    z-index: 99;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-family: Helvetica, arial, sans-serif;
    font-size: 1.5vw;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;
    mix-blend-mode: difference;
}

</style>

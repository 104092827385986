<template>
    <div>
        <button @click="addItemLayout">Add an item dynamically</button>

        <grid-layout v-model:layout="layout"
                     :responsive-layouts="layouts"
                     :col-num="12"
                     :row-height="30"
                     :is-draggable="draggable"
                     :is-resizable="resizable"
                     :vertical-compact="true"
                     :use-css-transforms="true"
                     :responsive="responsive"
                     @breakpoint-changed="breakpointChangedEvent"
        >
            <grid-item v-for="(item, i) in layout"
                       :key="i"
                       :x="item.x"
                       :y="item.y"
                       :w="item.w"
                       :h="item.h"
                       :i="item.i"
            >
                <div class="GridLayout-RemoveItem--Container">
                    <span class="GridLayout-Item--remove" @click="removeItem(item.i)">
                        <Tooltip :tooltipText="'Delete'"
                        :flow="'up'">x
                        </Tooltip>
                    </span>
                </div>
                <!-- To display the unique id of the gridLayout item -->
                <!-- <span class="text">{{item.i}}</span> -->
            </grid-item>
        </grid-layout>
    </div>
</template>

<script>
import { GridLayout, GridItem } from "vue3-grid-layout"
import Tooltip from "../../../components/edit_mode/tooltip/Tooltip.vue";


export default {
    components: {
        GridLayout,
        GridItem,
        Tooltip
    },
    props:{
            sectionID:{type: String, required: true},
    },
    data() {
        /* Layout can contain anything:
              - videos (upload features)
              - pictures  (upload features)
              - gif   (upload features)
              - audio (upload features)
              - text   (input form features)
              - etc
        */
        let testLayouts = {
            md: [
                {"x":0, "y":0, "w":2, "h":2, "i":"s-"+ this.sectionID + "md_0"},
                {"x":2, "y":0, "w":2, "h":4, "i":"s-"+ this.sectionID + "md_1"},
                {"x":4, "y":0, "w":2, "h":5, "i":"s-"+ this.sectionID + "md_2"},
                {"x":6, "y":0, "w":2, "h":3, "i":"s-"+ this.sectionID + "md_3"},
                {"x":2, "y":4, "w":2, "h":3, "i":"s-"+ this.sectionID + "md_4"},
                {"x":4, "y":5, "w":2, "h":3, "i":"s-"+ this.sectionID + "md_5"},
                {"x":0, "y":2, "w":2, "h":5, "i":"s-"+ this.sectionID + "md_6"},
                {"x":2, "y":7, "w":2, "h":5, "i":"s-"+ this.sectionID + "md_7"},
                {"x":4, "y":8, "w":2, "h":5, "i":"s-"+ this.sectionID + "md_8"},
                {"x":6, "y":3, "w":2, "h":4, "i":"s-"+ this.sectionID + "md_9"},
                {"x":0, "y":7, "w":2, "h":4, "i":"s-"+ this.sectionID + "md_10"},
                {"x":2, "y":19, "w":2, "h":4, "i":"s-"+ this.sectionID + "md_11"},
                {"x":0, "y":14, "w":2, "h":5, "i":"s-"+ this.sectionID + "md_12"},
                {"x":2, "y":14, "w":2, "h":5, "i":"s-"+ this.sectionID + "md_13"},
                {"x":4, "y":13, "w":2, "h":4, "i":"s-"+ this.sectionID + "md_14"},
                {"x":6, "y":7, "w":2, "h":4, "i":"s-"+ this.sectionID + "md_15"},
                {"x":0, "y":19, "w":2, "h":5, "i":"s-"+ this.sectionID + "md_16"},
                {"x":8, "y":0, "w":2, "h":2, "i":"s-"+ this.sectionID + "md_17"},
                {"x":0, "y":11, "w":2, "h":3, "i":"s-"+ this.sectionID + "md_18"},
                {"x":2, "y":12, "w":2, "h":2, "i":"s-"+ this.sectionID + "md_19"}
            ],
            lg: [
                {"x":0,"y":0,"w":2,"h":2,"i":"s-"+ this.sectionID + "lg_0"},
                {"x":2,"y":0,"w":2,"h":4,"i":"s-"+ this.sectionID + "lg_1"},
                {"x":4,"y":0,"w":2,"h":5,"i":"s-"+ this.sectionID + "lg_2"},
                {"x":6,"y":0,"w":2,"h":3,"i":"s-"+ this.sectionID + "lg_3"},
                {"x":8,"y":0,"w":2,"h":3,"i":"s-"+ this.sectionID + "lg_4"},
                {"x":10,"y":0,"w":2,"h":3,"i":"s-"+ this.sectionID + "lg_5"},
                {"x":0,"y":5,"w":2,"h":5,"i":"s-"+ this.sectionID + "lg_6"},
                {"x":2,"y":5,"w":2,"h":5,"i":"s-"+ this.sectionID + "lg_7"},
                {"x":4,"y":5,"w":2,"h":5,"i":"s-"+ this.sectionID + "lg_8"},
                {"x":6,"y":4,"w":2,"h":4,"i":"s-"+ this.sectionID + "lg_9"},
                {"x":8,"y":4,"w":2,"h":4,"i":"s-"+ this.sectionID + "lg_10"},
                {"x":10,"y":4,"w":2,"h":4,"i":"s-"+ this.sectionID + "lg_11"},
                {"x":0,"y":10,"w":2,"h":5,"i":"s-"+ this.sectionID + "lg_12"},
                {"x":2,"y":10,"w":2,"h":5,"i":"s-"+ this.sectionID + "lg_13"},
                {"x":4,"y":8,"w":2,"h":4,"i":"s-"+ this.sectionID + "lg_14"},
                {"x":6,"y":8,"w":2,"h":4,"i":"s-"+ this.sectionID + "lg_15"},
                {"x":8,"y":10,"w":2,"h":5,"i":"s-"+ this.sectionID + "lg_16"},
                {"x":10,"y":4,"w":2,"h":2,"i":"s-"+ this.sectionID + "lg_17"},
                {"x":0,"y":9,"w":2,"h":3,"i":"s-"+ this.sectionID + "lg_18"},
                {"x":2,"y":6,"w":2,"h":2,"i":"s-"+ this.sectionID + "lg_19"}
            ],
        };
        return {
            layouts: testLayouts,
            layout: testLayouts["lg"],
            draggable: true,  // false ==> to pass to the deploy mode
            resizable: true,   // false ==> to pass to the deploy mode
            responsive: true,
            index: 0,
            colNum: 12,
        }
    },
    methods: {
        breakpointChangedEvent: function(newBreakpoint, newLayout){
            console.log("BREAKPOINT CHANGED breakpoint=", newBreakpoint, ", layout: ", newLayout );
        },
        // Insert the correct path of the picture (FOR TESTING)
        getImgUrlImageProjects: function (projectName, imagePath) {
            return require('@/assets/img/projects/' + projectName + '/' + imagePath);
        },
        // --- Add and remove Grid Layout Item ---
        addItemLayout: function () {
            // Increment the counter to ensure key is always unique.
            this.indexLayout = this.layout.length - 1
            this.indexLayout++;

            // Add a new item. It must have a unique key!
            this.layout.push({
                x: (this.layout.length * 2) % (this.colNum || 12),
                y: this.layout.length + (this.colNum || 12), // puts it at the bottom
                w: 2,
                h: 2,
                i: "s-"+ this.sectionID + "_" + this.indexLayout + '-GRID1',
            });
        },
        removeItemLayout: function (val) {
            const index = this.layout.map(item => item.i).indexOf(val);
            this.layout.splice(index, 1);
        },
    }
}
</script>

<style scoped>
/* .vue-grid-layout {
    background: #eee;
} */

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

/* Remove feature css */
.GridLayout-RemoveItem--Container {
   margin-left: 1em;
}

.GridLayout-RemoveItem--Container:hover {
    cursor: pointer;
}

.GridLayout-Item--remove {
    padding-top: 0.3em;
    margin-left: 8.5em;
    position: fixed;
    z-index: 99;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-family: Helvetica, arial, sans-serif;
    font-size: 1.5vw;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;
    mix-blend-mode: difference;
}
</style>

<template>
  <Transition name="modal">
    <div v-if="show" class="ZoomImageModal--modal-mask">
      <div class="ZoomImageModal--modal-container">
        <div class="ZoomImageModal--modal-header">
          <slot name="ZoomImageModal--header">default header</slot>
          <!-- default footer -->
          <button
              class="ZoomImageModal--modal-default-button"
              @click="$emit('close')"
            >CLOSE</button>
        </div>

        <div class="ZoomImageModal--modal-body">
          <slot name="ZoomImageModal--body">default body</slot>
        </div>

        <div class="ZoomImageModal--modal-footer">
          <slot name="ZoomImageModal--footer">
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
// import Tooltip from "../../../components/edit_mode/tooltip/Tooltip.vue";

    // Option Api syntax
    // https://vuejs.org/examples/#modal
    export default {
      name: "zoom-image-modal",
      components:{
        // Tooltip
      },
      data() {
            return {
            }
          },
        props: {
            show: Boolean,
        }
    }
</script>

<style>

.ZoomImageModal--modal-container {
  width: 100%;
  height: 100%;
  margin: auto;/* haut | droit | bas | gauche */
  margin-left: auto;
  margin-right: auto;
  padding: 20px 30px;
  background-color: black;
  border-radius: 2px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33); */
  transition: all 0.3s ease;
  /*
     https://stackoverflow.com/questions/2937929/how-to-make-a-div-scrollable-instead-of-body
    .mainSection needs to have a height. Otherwise the browser can not know what it should consider overflow.
  */
  overflow-y: scroll;
}

.ZoomImageModal--modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(74 68 68 / 70%);
  display: flex;
  transition: opacity 0.3s ease;
}


.ZoomImageModal--modal-header h2 {
  margin-top: 0;
  color: #42b983;
  text-align: center;
}

.ZoomImageModal--modal-body {
  margin: 20px 0;
}

.ZoomImageModal--modal-default-button {
  top: 17px;
  right: 86px;
  position: absolute;
}



.ZoomImageModal--picture {
  width: 100%;
  height: auto;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
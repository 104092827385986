<template>
    <div>
        <button @click="addItemLayout">Add an item dynamically</button>

        <grid-layout v-model:layout="layout"
                     :col-num="12"
                     :row-height="30"
                     :is-draggable="true"
                     :is-resizable="true"
                     :vertical-compact="true"
                     :use-css-transforms="true"
        >
            <grid-item v-for="(item, i) in layout"
                       :key="i"
                       :x="item.x"
                       :y="item.y"
                       :w="item.w"
                       :h="item.h"
                       :i="item.i"
                       drag-allow-from=".vue-draggable-handle"
                       drag-ignore-from=".no-drag"
            >
                <div class="GridLayout-RemoveItem--Container">
                    <span class="GridLayout-Item--remove" @click="removeItem(item.i)">
                        <Tooltip :tooltipText="'Delete'"
                        :flow="'up'">x
                        </Tooltip>
                    </span>
                </div>
                <div class="text">
                    <div class="vue-draggable-handle"></div>
                    <div class="no-drag">
                        <!-- To display the unique id of the gridLayout item -->
                        <!-- <span>{{item.i}}</span> -->
                        <br/>
                        <button :id="`button-grid-item + ${i}`">click</button>
                    </div>
                </div>
            </grid-item>
        </grid-layout>
    </div>
</template>

<script>
import { GridLayout, GridItem } from "vue3-grid-layout"
import Tooltip from "../../../components/edit_mode/tooltip/Tooltip.vue";

export default {
    components: {
        GridLayout,
        GridItem,
        Tooltip
    },
    props:{
            sectionID:{type: String, required: true},
    },
    data() {
        return {
            /* Layout can contain anything:
              - videos (upload features)
              - pictures  (upload features)
              - gif   (upload features)
              - audio (upload features)
              - text   (input form features)
              - etc
            */
            layout: [
                {"x":0,"y":0,"w":2,"h":2,"i":"s-"+ this.sectionID + "_0"},
                {"x":2,"y":0,"w":2,"h":4,"i":"s-"+ this.sectionID + "_1"},
                {"x":4,"y":0,"w":2,"h":5,"i":"s-"+ this.sectionID + "_2"},
                {"x":6,"y":0,"w":2,"h":3,"i":"s-"+ this.sectionID + "_3"},
                {"x":8,"y":0,"w":2,"h":3,"i":"s-"+ this.sectionID + "_4"},
                {"x":10,"y":0,"w":2,"h":3,"i":"s-"+ this.sectionID + "_5"},
                {"x":0,"y":5,"w":2,"h":5,"i":"s-"+ this.sectionID + "_6"},
                {"x":2,"y":5,"w":2,"h":5,"i":"s-"+ this.sectionID + "_7"},
                {"x":4,"y":5,"w":2,"h":5,"i":"s-"+ this.sectionID + "_8"},
                {"x":6,"y":4,"w":2,"h":4,"i":"s-"+ this.sectionID + "_9"},
                {"x":8,"y":4,"w":2,"h":4,"i":"s-"+ this.sectionID + "_10"},
                {"x":10,"y":4,"w":2,"h":4,"i":"s-"+ this.sectionID + "_11"},
                {"x":0,"y":10,"w":2,"h":5,"i":"s-"+ this.sectionID + "_12"},
                {"x":2,"y":10,"w":2,"h":5,"i":"s-"+ this.sectionID + "_13"},
                {"x":4,"y":8,"w":2,"h":4,"i":"s-"+ this.sectionID + "_14"},
                {"x":6,"y":8,"w":2,"h":4,"i":"s-"+ this.sectionID + "_15"},
                {"x":8,"y":10,"w":2,"h":5,"i":"s-"+ this.sectionID + "_16"},
                {"x":10,"y":4,"w":2,"h":2,"i":"s-"+ this.sectionID + "_17"},
                {"x":0,"y":9,"w":2,"h":3,"i":"s-"+ this.sectionID + "_18"},
                {"x":2,"y":6,"w":2,"h":2,"i":"s-"+ this.sectionID + "_19"}
            ],
            draggable: true,  // false ==> to pass to the deploy mode
            resizable: true,  // false ==> to pass to the deploy mode
            index: 0,
            eventLog: [],
            colNum: 12,
        }
    },
    watch: {
        eventLog: function() {
            const eventsDiv = this.$refs.eventsDiv;
            eventsDiv.scrollTop = eventsDiv.scrollHeight;
        }
    },
    methods: {
        moveEvent: function(i, newX, newY){
            const msg = "MOVE i=" + i + ", X=" + newX + ", Y=" + newY;
            this.eventLog.push(msg);
            console.log(msg);

        },
        movedEvent: function(i, newX, newY){
            const msg = "MOVED i=" + i + ", X=" + newX + ", Y=" + newY;
            this.eventLog.push(msg);
            console.log(msg);

        },
        resizeEvent: function(i, newH, newW, newHPx, newWPx){
            const msg = "RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);
        },
        resizedEvent: function(i, newX, newY, newHPx, newWPx){
            const msg = "RESIZED i=" + i + ", X=" + newX + ", Y=" + newY + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);

        },
        containerResizedEvent: function(i, newH, newW, newHPx, newWPx){
            const msg = "CONTAINER RESIZED i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);
        },

        layoutCreatedEvent: function(newLayout){
            this.eventLog.push("Created layout");
            console.log("Created layout: ", newLayout)
        },
        layoutBeforeMountEvent: function(newLayout){
            this.eventLog.push("beforeMount layout");
            console.log("beforeMount layout: ", newLayout)
        },
        layoutMountedEvent: function(newLayout){
            this.eventLog.push("Mounted layout");
            console.log("Mounted layout: ", newLayout)
        },
        layoutReadyEvent: function(newLayout){
            this.eventLog.push("Ready layout");
            console.log("Ready layout: ", newLayout)
        },
        layoutUpdatedEvent: function(newLayout){
            this.eventLog.push("Updated layout");
            console.log("Updated layout: ", newLayout)
        },
        // Insert the correct path of the picture (FOR TESTING)
        getImgUrlImageProjects: function (projectName, imagePath) {
            return require('@/assets/img/projects/' + projectName + '/' + imagePath);
        },
        // --- Add and remove Grid Layout Item ---
        addItemLayout: function () {
            // Increment the counter to ensure key is always unique.
            this.indexLayout = this.layout.length - 1
            this.indexLayout++;

            // Add a new item. It must have a unique key!
            this.layout.push({
                x: (this.layout.length * 2) % (this.colNum || 12),
                y: this.layout.length + (this.colNum || 12), // puts it at the bottom
                w: 2,
                h: 2,
                i: "s-"+ this.sectionID + "_" + this.indexLayout + '-GRID1',
            });
        },
        removeItemLayout: function (val) {
            const index = this.layout.map(item => item.i).indexOf(val);
            this.layout.splice(index, 1);
        },
    },
    mounted(){
        console.log("this.sectionID =", this.sectionID)
        console.log("this.layout    =", this.layout)
    }
}
</script>

<style scoped>
/* .vue-grid-layout {
    background: #eee;
} */

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    right: 0;
    padding: 0 8px 8px 0;
    background-origin: content-box;
    background-color: black;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
}

.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}

.eventsJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
    height: 100px;
    overflow-y: scroll;
}

/* Remove feature css */
.GridLayout-RemoveItem--Container {
   margin-left: 1em;
}

.GridLayout-RemoveItem--Container:hover {
    cursor: pointer;
}

.GridLayout-Item--remove {
    padding-top: 0.3em;
    margin-left: 8.5em;
    position: fixed;
    z-index: 99;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-family: Helvetica, arial, sans-serif;
    font-size: 1.5vw;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;
    mix-blend-mode: difference;
}
</style>
